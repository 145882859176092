const initialState = {
  precios: {},
  infoAsesoria: {},
  infoRevisa: {},
  dataForm: {},
  infoMembresia: {},
  infoUserPlan: {}
}

const Dash_User_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRECIOS':
      return { ...state, precios: action.precios }
    case 'SET_INFO_ASESORIA':
      return { ...state, infoAsesoria: action.infoAsesoria }
    case 'SET_INFO_REVISA':
      return { ...state, infoRevisa: action.infoRevisa }
    case 'SET_INFO_FORM':
      return { ...state, dataForm: action.dataForm }
    case 'SET_INFO_MEMBRESIA':
      return { ...state, infoMembresia: action.infoMembresia }
      case 'SET_INFO_USER_PLAN':
        return { ...state, infoUserPlan: action.infoUserPlan }
    default:
      return state
  }
}

export const getDocData = (state) => state.initialState.selectedDocumentService
export const getinfoRevisa = (state) => state.dash_user.infoRevisa

export const dataFormPago = (state) => state.dash_user.dataForm

export const GetPrecios = (state) => state.dash_user.precios

export const getInfoMembresia = (state) => state.dash_user.infoMembresia

export const getInfoUserPlan = (state) => state.dash_user.infoUserPlan

export default Dash_User_Reducer
