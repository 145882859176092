// ** Logo
import logo from '@src/assets/images/logo/logo.png'
import './logo.css'
const SpinnerComponent = () => {
  return (
    <div className='Logo vh-100'>
      <div className='Logo vh-100 mb-2'><img className='fallback-logo' src='/logo_tgp_vision_consultores_png.png' alt='logo' /></div>
      <div className='loading mt-2'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
