const initialState = {
  infoPlan: {},
  userSub: {}
}

const Planes_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INFO_PLAN':
      return { ...state, infoPlan: action.infoPlan }
    default:
      return state
  }
}

export const getInfoPlan = (state) => state.plans.infoPlan

export default Planes_Reducer
